<template>
  <div>
    <div>
      <h1 class="mt-0 fs-b fs-2500 color-pr">Paiements directs</h1>
    </div>
    <div class="region region-content">
      <div id="block-pcr-theme-content">
        <div class="textBlock_list direct_payment">
          <div class="fr-grid-row textBlock_list_item is-odd">
            <div class="fr-col-0 fr-col-md-2"></div>
            <div class="fr-col-12 fr-col-md-9 fr-grid-row text_block_content">
              <div class="fr-col-md-3 fr-col-12 text_block_image fr-grid-row">
                <div class="fr-col-md-12 fr-col-6">
                  <div>
                    <div>
                      <img
                        src="../assets/img/pay_1.svg"
                        alt="image d'un pc avec sur l'écran une banque et un signe euro et un signe wifi"
                        typeof="foaf:Image"
                        class="img-responsive"
                        role="img"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="fr-col-md-1 fr-col-0"></div>
              <div class="fr-col-md-8 fr-col-12 text_block_text">
                <div class="fr-col-12 text_block_text_title">
                  <span>
                    Paiement des impôts locaux
                    <span class="pro_label">PRO</span>
                  </span>
                </div>
                <div class="fr-col-md-10 fr-col-12 text_block_text_description">
                  <div>
                    <p>
                      Directement depuis Portailpro.gouv, vous pouvez accéder
                      aux services de paiement des impôts locaux PRO pour :
                    </p>

                    <ul>
                      <li><span>payer vos impôts en ligne ;</span></li>
                      <li>
                        <span>
                          souscrire ou modifier votre souscription au
                          prélèvement mensuel ou au prélèvement à l’échéance de
                          vos impôts.</span
                        >
                      </li>
                    </ul>
                    <p>
                      Pour accéder à ces services, munissez-vous du numéro
                      fiscal et de la référence figurant sur votre avis.
                    </p>
                  </div>
                </div>
                <div class="fr-col-12 text_block_text_link">
                  <a
                    id="pai_01"
                    class="fr-btn fr-btn--secondary"
                    href="#"
                    @click="navigateToPaiementImpotsLocauxPro($event)"
                    >Accéder</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="fr-grid-row textBlock_list_item is-even">
            <div class="fr-col-0 fr-col-md-2"></div>
            <div class="fr-col-12 fr-col-md-9 fr-grid-row text_block_content">
              <div class="fr-col-md-3 fr-col-12 text_block_image fr-grid-row">
                <div class="fr-col-md-12 fr-col-6">
                  <div>
                    <div>
                      <img
                        src="../assets/img/pay_2.svg"
                        alt="image représentant un porte monnaie"
                        typeof="foaf:Image"
                        class="image img-responsive"
                        role="img"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="fr-col-md-1 fr-col-0"></div>
              <div class="fr-col-md-8 fr-col-12 text_block_text">
                <div class="fr-col-12 text_block_text_title">
                  <span> Paiement d’une facture publique </span>
                </div>
                <div class="fr-col-md-10 fr-col-12 text_block_text_description">
                  <div>
                    <p>
                      La Direction générale des Finances publiques (DGFiP), vous
                      permet de payer vos factures publiques (hors impôts) en
                      toute sécurité 24 h sur 24 et 7 jours sur 7. Vous pouvez
                      opter pour le paiement soit par carte bancaire soit par
                      prélèvement unique.
                    </p>

                    <p>
                      Pour accéder à ce service, munissez-vous de l’identifiant
                      structure publique précisé sur votre avis de somme à
                      payer, votre facture ou votre titre de perception.
                    </p>

                    <p>
                      Vous serez dirigé sur le site externe payfip.gouv.fr. Vous
                      pourrez revenir sur Portailpro.gouv via votre flèche
                      retour du navigateur.
                    </p>
                  </div>
                </div>
                <div class="fr-col-12 text_block_text_link">
                  <a
                    title="Payfip - Paiement d'une facture publique - site externe"
                    id="pai_02"
                    class="fr-btn fr-btn--secondary fr-icon-external-link-line fr-btn--icon-left"
                    href="#"
                    @click="navigateToPayFip($event)"
                    >Accéder</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="fr-grid-row textBlock_list_item is-odd">
            <div class="fr-col-0 fr-col-md-2"></div>
            <div class="fr-col-12 fr-col-md-9 fr-grid-row text_block_content">
              <div class="fr-col-md-3 fr-col-12 text_block_image fr-grid-row">
                <div class="fr-col-md-12 fr-col-6">
                  <div>
                    <div>
                      <img
                        src="../assets/img/pay_3.svg"
                        alt="image représentant un portable avec un signe euro et une empreinte digitale"
                        typeof="foaf:Image"
                        class="img-responsive"
                        role="img"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="fr-col-md-1 fr-col-0"></div>
              <div class="fr-col-md-8 fr-col-12 text_block_text">
                <div class="fr-col-12 text_block_text_title">
                  <span> Paiement de droits / taxes de Douane </span>
                </div>
                <div class="fr-col-md-10 fr-col-12 text_block_text_description">
                  <div>
                    <p>
                      La Douane propose un service en ligne simple, rapide et
                      sécurisé permettant le règlement par carte bancaire des
                      créances suivantes :
                    </p>

                    <ul>
                      <li>
                        <span>
                          Octroi de mer interne dont le montant n'excède pas
                          1500 euros ;</span
                        >
                      </li>
                      <li>
                        <span>
                          Sommes à payer en matière de Contributions Indirectes
                          (alcools et boissons alcooliques) dont le montant
                          n'excède pas 2 000 euros (fait générateur antérieur au
                          01/01/2024).</span
                        >
                      </li>
                    </ul>
                    <p>
                      Pour bénéficier de ce service en ligne, il vous suffit de
                      vous reporter à l'avis de paiement (octroi de mer) ou au
                      courriel envoyé par la douane (créances en matière de
                      contributions indirectes), sur lesquels figurent les
                      références de la créance concernée, ainsi que le mot de
                      passe associé.
                    </p>

                    <p>
                      Vous serez dirigé sur le site externe de la Douane. Vous
                      pourrez revenir sur Portailpro.gouv via votre flèche
                      retour du navigateur.
                    </p>
                  </div>
                </div>
                <div class="fr-col-12 text_block_text_link">
                  <a
                    title="Douane.gouv.fr - Paiement de droits / taxes de Douane - site externe"
                    id="pai_03"
                    class="fr-btn fr-btn--secondary fr-icon-external-link-line fr-btn--icon-left"
                    href="#"
                    @click="navigateToDouane($event)"
                    >Accéder</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LIENS_UTILES, SOURCE_PAGE_PAIEMENT_DIRECT } from '../constantes';
import SkipLinkMixin from '../mixins/skip-link-mixin';
import { mapActions, mapGetters } from 'vuex';
import { sendTag, isFeatureActive } from '../utils/methodsUtils';

export default {
  name: 'PaiementsDirectes',
  mixins: [SkipLinkMixin],
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      cds: false
    };
  },
  computed: {
    LIENS_UTILES() {
      return LIENS_UTILES;
    },
    urlMireDeConnexion() {
      return `${process.env.VUE_APP_DNS_WSO2}/user-portal/`;
    },
    user() {
      return {
        email: this.email
      };
    },
    ...mapGetters(['email']),
    isAuthentifie() {
      return !this.$route.meta.withoutAuth && this.email && this.email != '';
    }
  },
  methods: {
    ...mapActions(['setPreviousPath', 'setRouteData']),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    navigateToPaiementImpotsLocauxPro(e) {
      if (e) {
        e.preventDefault();
      }
      sendTag('paiements-directs', 'paiement-des-impots-locaux-pro', '', '');
      localStorage.setItem(
        'libelleTeleservice',
        'Paiement des impôts locaux PRO'
      );
      this.setPreviousPath('');
      const routeData = {
        source: SOURCE_PAGE_PAIEMENT_DIRECT,
        uri: process.env.VUE_APP_IMPOT_LOC_PRO_IFRAME
      };
      this.setRouteDataAndPush(routeData, 'Téléservice');
    },
    navigateToPayFip(e) {
      if (e) {
        e.preventDefault();
      }
      sendTag('paiements-directs', 'www-payfip-gouv-fr', '', '');
      window.open('https://www.payfip.gouv.fr');
    },
    navigateToDouane(e) {
      if (e) {
        e.preventDefault();
      }
      sendTag('paiements-directs', 'www-douane-gouv-fr', '', '');
      window.open('https://www.douane.gouv.fr/mcb_ihm/accueil-build.action');
    },
    setRouteDataAndPush(routeData, routeName) {
      this.setRouteData(routeData)
        .then(() => {
          this.$router.push({
            name: routeName
          });
        })
        .catch((error) => console.error(error));
    }
  },
  async mounted() {
    this.cds = isFeatureActive('PCR2-9132');
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style lang="scss" scoped>
.textBlock_list {
  margin-top: 1rem;
}

.textBlock_list.direct_payment .textBlock_list_item {
  margin-bottom: 5rem;
}
.fr-col-md-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
  width: 16.6667%;
}
.text_block_text_title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 10px;
  line-height: 1.5rem;
}

.text_block_image {
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
}
.pro_label {
  color: #2a2a2a;
  font-weight: bold;
}

.is-even {
  .text_block_content {
    display: flex;
    flex-direction: row-reverse;
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }
}

.img-responsive {
  width: 224.433px;
}

li {
  font-size: 1.5rem;
  line-height: 1 !important;
  margin-left: -0.5em;
}
li span {
  font-size: 1rem;
  line-height: 1 !important;
}
</style>
